import React from "react"
import Layout from "../componets/Layout"

export default function NOTFound() {
  return (
    <Layout>
      <div>
        <h1>page does not exist</h1>
      </div>
    </Layout>
  )
}
